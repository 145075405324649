import type { HTMLProps } from "react";

const LoadingSpinner = ({ className = "", ...props }: HTMLProps<HTMLDivElement>): JSX.Element => {
  return (
    <div className={`flex h-full flex-grow items-center justify-center ${className}`} {...props}>
      <span className="sr-only">Loading...</span>
      <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-600"></div>
    </div>
  );
};
export default LoadingSpinner;
