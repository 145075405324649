import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import LoadingSpinner from "@components/LoadingSpinner";

const Index = (): JSX.Element => {
  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (status === "loading") {
      return;
    }
    if (session && status === "authenticated") {
      router.push("/dashboard");
    } else {
      router.push("/auth/signin");
    }
  }, [status, session, router]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};

export default Index;
